<template>
  <div class="branche-summary">
    <div class="branche-summary-header">
        <h2>סניף {{branche_name}}</h2>
        <div @click="$emit('back_to_branche_grid')" class="back-btn">
            <i class="material-icons">arrow_back</i>
        </div>
    </div>
    <div class="branche-summary-content">
        <table>
            <tr>
                <th>תאריך ספירה</th>
                <th>כמות פריטים</th>
                <th></th>
                <th></th>
            </tr>
            <template v-for="[key,value] in branche_summary" :key="key">
                <tr>
                    <td>{{key}}</td>
                    <td>{{value.length}}</td>
                    <td><el-button @click="handle_open_counts_by_date({date:key,data:value})" type="primary">פתח</el-button></td>
                    <td><el-button @click="handle_export_to_scv(value)" type="success">ייצא לאקסל</el-button></td>
                </tr>
            </template>
        </table>
    </div>
    <SubSummary @close="handle_close_sub_summary" :data="selected_counts" v-if="show_sub_summary_counts_by_date" />
  </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { defineAsyncComponent } from '@vue/runtime-core';
import XLSX from "xlsx";
export default {
    emits:['back_to_branche_grid'],
    props:['branche_summary','branche_name'],
    components:{
        SubSummary:defineAsyncComponent(()=>import('./SubSummary.vue'))
    },
    setup(props){
        
        const selected_counts = ref(null)

        const show_sub_summary_counts_by_date = ref(false)

        const handle_open_counts_by_date = (data) => {
            show_sub_summary_counts_by_date.value = true
            selected_counts.value = data
        }

        const handle_close_sub_summary = () => {
            show_sub_summary_counts_by_date.value = false
            selected_counts.value = null
        }

        const handle_export_to_scv = (data)=>{
            let rows = JSON.parse(JSON.stringify(data))

            let workbook = XLSX.utils.book_new();

            let workSheetData =[]
            workSheetData.push([
                        'קוד',
                        'שם',
                        'כמות',
            ])
            
            rows.forEach((row)=>{
                workSheetData.push([
                    row['פריט'],
                    row['שם פריט'],
                    row.weight,
                ])
            })

            const workSheet = XLSX.utils.aoa_to_sheet(workSheetData)
            workbook.Workbook = {};
            workbook.Workbook.Views = [];
            workbook.Workbook.Views[0] = {};
            workbook.Workbook.Views[0].RTL = true;
            XLSX.utils.book_append_sheet(workbook, workSheet, `${props.branche_name}`);
            XLSX.writeFile(workbook, `${props.branche_name}.xlsx`);
        }

        return{
            handle_open_counts_by_date,
            show_sub_summary_counts_by_date,
            selected_counts,
            handle_close_sub_summary,
            handle_export_to_scv
        }
    }
}
</script>

<style scoped>
    .branche-summary{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .branche-summary-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .branche-summary-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    .back-btn{
        user-select: none;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
</style>